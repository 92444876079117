import { Suspense, lazy, ElementType, useEffect } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
// components
import LoadingScreen from "../components/LoadingScreen";
import PageWires from "../pages/PageWires";
import CustomersBankTransactions from "../pages/customersBank/CustomersBankTransactions";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes("/titleQ")} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  //request permission so we can send push notifications
  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) return;
    if (window.Notification && window.Notification.permission === "default") {
      window.Notification.requestPermission();
    }
  }, []);

  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/title" replace />,
          index: true
        },
        { path: "/title", element: <PageWires /> }
      ]
    },
    {
      path: "/title",
      element: <DashboardLayout />,
      children: [{ path: "/title", element: <PageWires /> }]
    },
    {
      path: "/titletotals",
      element: <DashboardLayout />,
      children: [{ path: "/titletotals", element: <PageWires /> }]
    },
    {
      path: "/exchange",
      element: <DashboardLayout />,
      children: [{ path: "/exchange", element: <PageWires /> }]
    },
    {
      path: "/customersbank",
      element: <DashboardLayout />,
      children: [{ path: "/customersbank", element: <CustomersBankTransactions /> }]
    },
    {
      path: "*",
      element: <Navigate to="/" />
    }
  ]);
}

// Dashboard
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
