import { Box, Typography, Stack } from "@mui/material";
import { BlockContentProps } from ".";

// ----------------------------------------------------------------------

export default function BlockContent({ label }: BlockContentProps) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: "column", md: "row" }}
      sx={{ width: 1, textAlign: { xs: "center", md: "left" } }}
    >
      <Box sx={{ p: 3 }}>
        <Typography gutterBottom fontWeight={600} fontSize={14}>
          {label ? label : "Drop or Select file"}
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Drop files here or click&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: "primary.main", textDecoration: "underline" }}
          >
            browse
          </Typography>
          &nbsp;through your&nbsp;machine
        </Typography>
      </Box>
    </Stack>
  );
}
