import { useEffect, useRef, useState } from "react";
import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";

// @mui
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  IconButton,
  Grid,
  Button,
  Tabs,
  Tab,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  LinearProgress,
  Box,
  CardContent,
  TextareaAutosize
} from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
// hooks
import useSettings from "../hooks/useSettings";
// components
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
// sections
import { ListHead, ListToolbar } from "../list";
import axios from "axios";
import { fCurrency, fTotals } from "../utils/formatNumber";
import useDebounce from "../hooks/useDebounce";
import { Wire } from "./types/Wire";
import ReactTooltip from "react-tooltip";
import Iconify from "../components/Iconify";
import { Status } from "./types/Status";
import useLocalStorage from "../hooks/useLocalStorage";
import Highlighter from "react-highlight-words";
import PostToSelectModal from "./wiresComponents/PostToSelectModal";
import PatriotSearchFoundModal from "./wiresComponents/PatriotSearchFoundModal";
import { DownloadFile, AddWireNotes } from "../components/Icons";
import WarningModel from "./wiresComponents/WarningModel";
//utils
import { convertToFileDownload } from "../utils/fileDownload";
import UploadInvoiceModal from "./wiresComponents/UploadInvoiceModal";
import { sendNewWirePushNotification } from "./functions/sendNewWirePushNotification";
// ----------------------------------------------------------------------

type WireResults = {
  wiresList: Wire[];
  count: number;
};
type PatriotSearchModelData = {
  isOpen: boolean;
  patriotSearchResult: any[];
};

type TotalsForTransactions = {
  transactionTotals: number;
  totalIncoming: number;
  totalOutgoing: number;
  netDifference: number;
};

export default function WiresTable() {
  const [connection, setConnection] = useState<HubConnection>();

  const [wireResults, setWireResults] = useState<WireResults>({
    wiresList: [],
    count: 0
  });

  const [identifier, setIdentifier] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [messageOpen, setOpenMessage] = useState(false);
  const [showTotals, setShowTotals] = useState<boolean>(window.location.pathname === "/titletotals");
  const [isOpenInvoiceModal, setIsOpenInvoiceModal] = useState(false);
  const [invoiceNotApplicable, setInvoiceNotApplicable] = useState<boolean>(false);
  const [postingTransactionId, setPostingTransactionId] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useLocalStorage<"Credit" | "Debit" | "internalTransfers">(
    "selectedTab",
    "Credit"
  );

  const [selectedBusiness, setSelectedBusiness] = useLocalStorage<"Madison Title" | "Madison Exchange">(
    "selectedBusiness",
    window.location.pathname === "/exchange" ? "Madison Exchange" : "Madison Title"
  );
  const latestWires = useRef<Wire[]>([]);
  const selectedTabRef = useRef<"Credit" | "Debit" | "internalTransfers">("Credit");

  const [internalTransfers, setInternalTransfers] = useLocalStorage<boolean>("internalTransfers", false);
  const debouncedSearchTextRef = useRef<string>("");

  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);

  const [selectedStatus, setSelectedStatus] = useLocalStorage<Status>("selectedStatus", "All");
  const [bankAccountList, setBankAccountList] = useState<string[]>([]);
  const [selectedBankAccounts, setSelectedBankAccounts] = useLocalStorage<string[]>("bankAccounts", []);

  const selectedBankAccountsRef = useRef<string[]>();
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);

  const [postToSelectModalState, setPostToSelectModalState] = useState<{
    wire: Wire | null;
    isOpen: boolean;
    wireType: "incoming" | "outgoing";
    id: number;
  }>({ wire: null, isOpen: false, wireType: "incoming", id: 0 });
  const [newNote, setNewNote] = useState<string>("");
  const [showTextArea, setShowTextArea] = useState<{
    id: string;
    shouldShow: boolean;
  }>();
  const [showWarning, setShowWarning] = useState<boolean>(false);

  latestWires.current = wireResults.wiresList;
  selectedTabRef.current = selectedTab;
  selectedBankAccountsRef.current = selectedBankAccounts;
  debouncedSearchTextRef.current = debouncedSearchText;

  const TEXAS_ACCOUNT = ["6726", "9982"];

  const connectHub = () => {
    const newConnection = new HubConnectionBuilder().withUrl("/hubs/wires").withAutomaticReconnect().build();

    setConnection(newConnection);
  };

  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  const [selectedWire, setSelectedWire] = useState<Wire | null>(null);
  const [patriotModalData, setPatriotModalData] = useState<PatriotSearchModelData>({
    isOpen: false,
    patriotSearchResult: []
  });

  const [warningModelData, setWarningModelData] = useState<number>(NaN);
  const { themeStretch } = useSettings();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const [selected, setSelected] = useState<string[]>([]);

  const [orderBy, setOrderBy] = useState("DateTime");

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalResults, setTotalResults] = useState<TotalsForTransactions>({
    transactionTotals: 0,
    totalIncoming: 0,
    totalOutgoing: 0,
    netDifference: 0
  });
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterBySearchText = (searchText: string) => {
    setSearchText(searchText);
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - wireResults.count) : 0;

  const isNotFound = !wireResults?.wiresList?.length && Boolean(searchText);
  const getParams = () => {
    let [fromDate, toDate] = dateRange;
    let toDateNew = toDate?.add(1, "day");
    const formatDate = (d: Dayjs) => d.format("MM/DD/YYYY");
    return {
      queryText: debouncedSearchText.trim(),
      page,
      rowsPerPage,
      orderBy,
      descending: order === "desc",
      transactionType: selectedTab,
      bankAccounts: selectedBankAccounts,
      status: selectedStatus,
      business: selectedBusiness,
      internalTransfers,
      dateStart: fromDate ? formatDate(fromDate) : "",
      dateEnd: toDateNew ? formatDate(toDateNew) : "",
      titleTotalPage: showTotals
    };
  };
  const getAndSetWiresList = async () => {
    setLoading(true);

    getWires(getParams());
    getTotals(getParams());
  };

  const getWires = async (params: any) => {
    const qs = require("qs");
    const { data } = await axios({
      method: "get",
      url: `/api/transaction/getWires`,
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    });
    setWireResults(data);
    setLoading(false);
  };

  const getAndSetBankAccountList = async () => {
    const { data } = await axios({
      method: "get",
      url: `/api/transaction/getBankAccounts?business=${selectedBusiness}`
    });
    setBankAccountList(data);
  };

  useEffect(() => {
    getAndSetWiresList();
  }, [
    page,
    debouncedSearchText,
    rowsPerPage,
    orderBy,
    order,
    selectedTab,
    selectedBankAccounts,
    selectedStatus,
    internalTransfers,
    dateRange
  ]);
  useEffect(() => {
    setPage(0);
  }, [debouncedSearchText, selectedBankAccounts, selectedStatus]);

  useEffect(() => {
    getAndSetBankAccountList();
    connectHub();
  }, []);

  const getTotals = async (params: any) => {
    const qs = require("qs");
    const { data } = await axios({
      method: "get",
      url: `/api/transaction/getTotals`,
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    });
    setTotalResults(data);
  };

  const checkIfPassesFilter = (wire: Wire) => {
    const { current: selectedTab } = selectedTabRef;
    const { current: selectedBankAccounts } = selectedBankAccountsRef;
    const { current: searchText } = debouncedSearchTextRef;
    return (
      wire.transactionType === selectedTab &&
      page === 0 &&
      selectedBusiness === wire.business &&
      (!selectedBankAccounts || !selectedBankAccounts.length || selectedBankAccounts.includes(wire.account)) &&
      (!searchText ||
        wire.account.toLowerCase().includes(searchText) ||
        wire.wireNotes.toLowerCase().includes(searchText) ||
        wire.amount.toString().toLowerCase().includes(searchText) ||
        wire.imad?.toLowerCase().includes(searchText) ||
        wire.omad?.toLowerCase().includes(searchText) ||
        wire.receiver.toLowerCase().includes(searchText) ||
        wire.sender.toLowerCase().includes(searchText) ||
        wire.titleFileNumber.toLowerCase().includes(searchText))
    );
  };

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on("ReceiveNewWires", (wire) => {
            var passesFilter = checkIfPassesFilter(wire);
            if (passesFilter) {
              const updatedWires = [{ ...wire, newWire: true }, ...latestWires.current];
              setWireResults((wireResults) => ({
                wiresList: updatedWires,
                count: wireResults.count + 1
              }));
              sendNewWirePushNotification(wire);
            }
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);

  const getStatusLabel = (status: string, confirmedOrRejectedBy: string) => {
    return confirmedOrRejectedBy ? (
      <div
        data-tip={`${status === "Rejected Match" ? "Rejected" : "Confirmed"} by: ${confirmedOrRejectedBy}`}
        data-for="user"
      >
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (status === "Match not found" && "error") ||
            (status === "Confirmed Match" && "success") ||
            (status === "Rejected Match" && "warning") ||
            "default"
          }
        >
          {status}
        </Label>
      </div>
    ) : (
      <Label
        variant={theme.palette.mode === "light" ? "ghost" : "filled"}
        color={
          (status === "Match not found" && "error") ||
          (status === "Confirmed Match" && "success") ||
          (status === "Rejected Match" && "warning") ||
          "default"
        }
      >
        {status}
      </Label>
    );
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });
  const setConfirmed = async (id: string) => {
    const { data } = await axios.post("/api/transaction/setConfirmed", {
      id: +id,
      note: newNote.trim()
    });
    setNewNote("");
    setWireResults({
      ...wireResults,
      wiresList: wireResults.wiresList.map((w) => (w.id != id ? w : data.transaction))
    });
    setPatriotModalData({
      isOpen: data.transaction.patriotSearch,
      patriotSearchResult: data.patriotSearchResult?.names
    });
  };

  const setRejected = async (id: string) => {
    const { data } = await axios.post("/api/transaction/setRejected", {
      id: +id
    });
    setWireResults({
      ...wireResults,
      wiresList: wireResults.wiresList.map((w) => (w.id != id ? w : data))
    });
  };
  const getBalance = async (titleFileNumber: string) => {
    const getFileBalance = {
      fileNumber: titleFileNumber
    };
    const { data } = await axios.post("/api/transaction/getbalances", getFileBalance);
    setWarningModelData(data);
  };

  const saveLoanNumber = async (saveSelectedWire = false) => {
    const { data } = await axios.post("/api/transaction/editLoanNumber", {
      id: selectedWire?.id,
      number: selectedWire?.titleFileNumber,
      note: newNote.trim()
    });
    setNewNote("");
    setWireResults({
      ...wireResults,
      wiresList: wireResults.wiresList.map((w) => (w.id != selectedWire?.id ? w : data.transaction))
    });
    setSelectedWire(saveSelectedWire ? data.transaction : null);
    if (data.message) {
      setErrorTitle("Notice");
      setErrorMessage(data.message);
      setOpenMessage(true);
    }
  };
  const genaratePdf = async (id: string) => {
    const { data } = await axios.get(`/api/transaction/generate?id=${id}&note=${newNote}`);
    convertToFileDownload(data.emailBody, `${data.description}.pdf`);
  };

  const postToSelect = async (sendToEarnestMoney: boolean, id: number, applyToType: "Lender" | "AllBuyers") => {
    if (
      postToSelectModalState.wire &&
      warningModelData < postToSelectModalState?.wire?.amount &&
      selectedTab === "Debit"
    ) {
      setShowWarning(true);
      return;
    }

    setPostingTransactionId(id.toString());
    const endpoint = selectedTab === "Credit" ? "postToSelectIncoming" : "postToSelectOutgoing";
    const { data } = await axios.post("/api/transaction/" + endpoint, {
      id,
      sendToEarnestMoney,
      applyToType: applyToType === "Lender" ? 0 : 1
    });
    if (data.statusInfo.status != "Success") {
      setErrorTitle("We couldn't proccess your request");
      if (data.statusInfo.errorMessage.includes("No ledger found for file number")) {
        setErrorMessage(`Trust account not selected for order number “${data.transaction.titleFileNumber}”`);
        await getSetIdentifier(data.transaction.titleFileNumber);
        setOpenMessage(true);
      } else {
        setErrorMessage(data.statusInfo.errorMessage);
        setOpenMessage(true);
      }
    } else {
      setWireResults({
        ...wireResults,
        wiresList: wireResults.wiresList.map((w) => (w.id != id.toString() ? w : data.transaction))
      });
    }
    setPostToSelectModalState({ ...postToSelectModalState, isOpen: false });
    setPostingTransactionId(null);
  };

  const TABLE_HEAD = [
    { id: "DateTime", label: "DATE/TIME", alignRight: false },
    { id: "Account", label: "ACCOUNT", alignRight: false },
    { id: "Amount", label: "AMOUNT", alignRight: false },
    { id: "WireNotes", label: "NOTES", alignRight: false },
    { id: "TitleFileNumber", label: "TITLE FILE #", alignRight: false },
    {
      id: selectedTab === "Credit" ? "Sender" : "Recipient",
      label: selectedTab === "Credit" ? "SENDER" : "RECIPIENT",
      alignRight: false
    },
    { id: "Status", label: "STATUS", alignRight: false },
    { id: "Fedref", label: "FEDREF", alignRight: false }
  ];

  const getSetIdentifier = async (titleFileNumber: string) => {
    const { data } = await axios.get(`/proxy/api/fundstransfer/getorderguid?filenumber=${titleFileNumber}`);
    setIdentifier(data.id);
  };

  const onModalPendingTransactionClick = async (transactionId: string, sendToEarnestMoney: boolean, id: number) => {
    setPostingTransactionId(id.toString());
    const { data } = await axios.post("/api/Transaction/SetPendingWireToPosted", {
      transactionId,
      sendToEarnestMoney,
      id
    });
    if (data.statusInfo.status != "Success") {
      setErrorTitle("We couldn't proccess your request");
      if (data.statusInfo.errorMessage.includes("No ledger found for file number")) {
        setErrorMessage(`Trust account not selected for order number “${data.transaction.titleFileNumber}”`);
        await getSetIdentifier(data.transaction.titleFileNumber);
        setOpenMessage(true);
      } else {
        setErrorMessage(data.statusInfo.errorMessage);
        setOpenMessage(true);
      }
    } else {
      setWireResults({
        ...wireResults,
        wiresList: wireResults.wiresList.map((w) => (w.id != id.toString() ? w : data.transaction))
      });
    }
    setPostToSelectModalState({ ...postToSelectModalState, isOpen: false });
    setPostingTransactionId(null);
  };

  const closeMessage = () => {
    setOpenMessage(false);
    setErrorMessage("");
    setIdentifier(null);
  };

  return (
    <>
      <UploadInvoiceModal
        isOpen={isOpenInvoiceModal}
        onCloseClick={() => {
          setIsOpenInvoiceModal(false);
          setSelectedWire(null);
          setInvoiceNotApplicable(false);
        }}
        selectedWire={selectedWire}
        setIsOpenInvoiceModal={setIsOpenInvoiceModal}
        setConfirmed={setConfirmed}
        setSelectedWire={setSelectedWire}
        invoiceNotApplicable={invoiceNotApplicable}
        setInvoiceNotApplicable={setInvoiceNotApplicable}
      />
      {showWarning && (
        <WarningModel
          amount={warningModelData}
          onClose={() => {
            setWarningModelData(NaN);
            setShowWarning(false);
            setPostToSelectModalState({ ...postToSelectModalState, isOpen: false });
            setPostingTransactionId(null);
          }}
        />
      )}
      {showTotals && (
        <Card
          style={{
            display: "flex-start",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CardContent>
            <Typography variant="h5" component="div">
              Totals
            </Typography>
            <TableWrap>
              <div className="column ammount">
                <div>{totalResults?.transactionTotals} Transactions</div>
                <div>{fTotals(totalResults?.totalIncoming?.toFixed(2)) || ""} </div>
                <div>{fTotals(totalResults?.totalOutgoing?.toFixed(2)) || ""} </div>
                <div>{fTotals(totalResults?.netDifference?.toFixed(2)) || ""}</div>
              </div>
              <div className="column title">
                <div>&nbsp;</div>
                <div>Incoming</div>
                <div>Outgoing</div>
                <div>Net Difference</div>
              </div>
            </TableWrap>
          </CardContent>
        </Card>
      )}

      {postToSelectModalState.isOpen && (
        <PostToSelectModal
          wire={postToSelectModalState.wire}
          wireType={postToSelectModalState.wireType}
          onPendingTransactionClick={onModalPendingTransactionClick}
          onNewTransactionClick={postToSelect}
          id={postToSelectModalState.id}
          isOpen={postToSelectModalState.isOpen}
          onCloseClick={() =>
            setPostToSelectModalState({
              ...postToSelectModalState,
              isOpen: false
            })
          }
          postingTransactionId={postingTransactionId}
          selectedTab={selectedTab}
        />
      )}
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Grid container justifyContent="space-between"></Grid>
        <Card>
          <Box sx={{ width: "100%", position: "relative" }}>
            {loading && <LinearProgress sx={{ width: "100%", position: "absolute" }} />}
          </Box>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={selectedTab}
            sx={{ px: 2, bgcolor: "background.neutral" }}
          >
            <Tab onClick={() => setSelectedTab("Credit")} disableRipple label="Incoming" value="Credit" />
            <Tab onClick={() => setSelectedTab("Debit")} disableRipple label="Outgoing" value="Debit" />
          </Tabs>
          <Divider />
          <ListToolbar
            numSelected={selected.length}
            filterName={searchText}
            onFilterName={handleFilterBySearchText}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            bankAccountList={bankAccountList}
            selectedBankAccounts={selectedBankAccounts}
            setSelectedBankAccounts={setSelectedBankAccounts}
            internalTransfers={internalTransfers}
            setInternalTransfers={setInternalTransfers}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {patriotModalData?.isOpen && (
                <PatriotSearchFoundModal
                  patriotSearchResult={patriotModalData?.patriotSearchResult}
                  onClose={() =>
                    setPatriotModalData({
                      isOpen: false,
                      patriotSearchResult: []
                    })
                  }
                />
              )}

              <Table size="small">
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={wireResults.count}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {wireResults.wiresList.map((row) => {
                    const {
                      id,
                      dateString,
                      timeString,
                      account,
                      amount,
                      wireNotes,
                      titleFileNumber,
                      sender,
                      receiver,
                      status,
                      imad,
                      omad,
                      confirmedOrRejectedBy,
                      postedBy,
                      savedToWeb,
                      newWire,
                      isPostedToRegister,
                      patriotSearch
                    } = row;

                    return (
                      <TableRow
                        hover={!newWire}
                        key={id}
                        sx={{
                          backgroundColor: newWire
                            ? isLight
                              ? theme.palette.success.lighter
                              : theme.palette.success.darker
                            : null,
                          borderBottom: "1px solid #212b361f",
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:first-of-type td, &:first-of-type th": {
                            border: 0
                          }
                        }}
                      >
                        <TableCell sx={{ alignItems: "center" }}>
                          <Typography variant="subtitle2">{dateString}</Typography>
                          <Typography variant="subtitle2" color="GrayText">
                            {timeString}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {account && !account.includes("xx") && "xx"}
                          <Highlighter
                            searchWords={[debouncedSearchText]}
                            autoEscape={true}
                            textToHighlight={account || ""}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Highlighter
                            searchWords={[fCurrency(debouncedSearchText).toString()]}
                            autoEscape={true}
                            textToHighlight={fCurrency(amount?.toFixed(2)) || ""}
                          />
                        </TableCell>
                        <TableCell>
                          <div data-tip={wireNotes} data-for="notes">
                            <Typography maxWidth={150} noWrap variant="body2">
                              <Highlighter
                                searchWords={[debouncedSearchText]}
                                autoEscape={true}
                                textToHighlight={wireNotes || ""}
                              />
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell sx={{ minWidth: 272 }}>
                          <Grid container spacing={0.5} alignItems="center">
                            <Grid item>
                              <TextField
                                size="small"
                                sx={{
                                  width: 136,
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: !titleFileNumber ? "" : "transparent !important"
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1817A8 !important"
                                  },
                                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1817A8 !important"
                                  }
                                }}
                                defaultValue={titleFileNumber}
                                onChange={(e) =>
                                  setSelectedWire({
                                    ...row,
                                    titleFileNumber: e.target.value
                                  })
                                }
                              />
                            </Grid>
                            {status === "Auto matched" && selectedWire?.id !== id && !showTextArea?.shouldShow && (
                              <>
                                <Grid item>
                                  <IconButton
                                    color="success"
                                    size="small"
                                    onClick={() => {
                                      if (selectedTab === "Debit" && TEXAS_ACCOUNT.includes(row?.account)) {
                                        setSelectedWire(row);
                                        setIsOpenInvoiceModal(true);
                                        return;
                                      }
                                      setConfirmed(id);
                                    }}
                                  >
                                    <Iconify icon="eva:checkmark-fill" width={20} height={20} />
                                  </IconButton>
                                </Grid>
                                <Grid item>
                                  <IconButton color="error" size="small" onClick={() => setRejected(id)}>
                                    <Iconify icon="eva:close-fill" width={20} height={20} />
                                  </IconButton>
                                </Grid>
                              </>
                            )}
                            {(status === "Auto matched" || selectedWire?.id === id) &&
                              selectedBusiness === "Madison Exchange" &&
                              !showTextArea?.shouldShow && (
                                <Grid
                                  item
                                  data-tip={`Add Additional Description to STW for Transaction ${titleFileNumber}`}
                                  data-for="addNotes"
                                >
                                  <IconButton
                                    onClick={() => {
                                      setShowTextArea({
                                        id: id,
                                        shouldShow: true
                                      });
                                    }}
                                  >
                                    <AddWireNotes width={14} height={14} />
                                  </IconButton>
                                </Grid>
                              )}
                            {showTextArea?.id === id && showTextArea.shouldShow && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <TextField
                                  sx={{ maxWidth: "150px" }}
                                  size="small"
                                  value={newNote}
                                  onChange={(e) => setNewNote(e.target.value)}
                                />
                                <div
                                  style={{
                                    display: "flex"
                                  }}
                                >
                                  <Button
                                    size="small"
                                    onClick={() => {
                                      setNewNote("");
                                      setShowTextArea({
                                        id: "",
                                        shouldShow: false
                                      });
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    size="small"
                                    onClick={() => {
                                      setShowTextArea({
                                        id: "",
                                        shouldShow: false
                                      });

                                      {
                                        selectedWire?.id === id ? saveLoanNumber() : setConfirmed(id);
                                      }
                                    }}
                                  >
                                    STW
                                  </Button>
                                </div>
                              </div>
                            )}

                            {selectedWire?.id === id && (
                              <Grid item>
                                <Button
                                  variant="contained"
                                  onClick={async () => {
                                    setSelectedWire(row);
                                    if (selectedTab === "Debit" && TEXAS_ACCOUNT.includes(row?.account)) {
                                      await saveLoanNumber(true);

                                      setIsOpenInvoiceModal(true);
                                      return;
                                    }
                                    saveLoanNumber();
                                  }}
                                >
                                  Save
                                </Button>
                              </Grid>
                            )}
                            {status === "Confirmed Match" && !isPostedToRegister && selectedWire?.id != id && (
                              <Grid item>
                                <Button
                                  size="small"
                                  onClick={() => {
                                    setPostToSelectModalState({
                                      wire: row,
                                      isOpen: true,
                                      wireType: selectedTab === "Credit" ? "incoming" : "outgoing",
                                      id: +id
                                    });
                                    getBalance(row.titleFileNumber);
                                  }}
                                >
                                  {postingTransactionId == id ? "Posting..." : "Post to Select"}
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <div data-tip={selectedTab === "Credit" ? sender : receiver} data-for="sender">
                            <Typography maxWidth={150} noWrap variant="body2">
                              {selectedTab === "Credit" ? (
                                <Highlighter
                                  searchWords={[debouncedSearchText]}
                                  autoEscape={true}
                                  textToHighlight={sender || ""}
                                />
                              ) : (
                                <Highlighter
                                  searchWords={[debouncedSearchText]}
                                  autoEscape={true}
                                  textToHighlight={receiver || ""}
                                />
                              )}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell sx={{ alignItems: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignContent: "center"
                            }}
                          >
                            <div
                              data-tip="Download documents"
                              data-for="documents"
                              style={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                marginRight: "10px"
                              }}
                            >
                              <DownloadFile onClick={() => genaratePdf(id)} width={20} height={20} />
                            </div>

                            {status === "Auto matched" ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  alignContent: "center"
                                }}
                              >
                                {" "}
                                <Typography variant="subtitle2" color="GrayText">
                                  Auto matched
                                </Typography>{" "}
                              </div>
                            ) : (
                              <Grid container width={200}>
                                {getStatusLabel(status, confirmedOrRejectedBy)}{" "}
                                {savedToWeb && (
                                  <div
                                    data-tip={`Saved by: ${confirmedOrRejectedBy}`}
                                    data-for="user"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      alignContent: "center"
                                    }}
                                  >
                                    <Iconify
                                      icon="eva:cloud-upload-outline"
                                      marginLeft={1}
                                      color={theme.palette.success.main}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                                {isPostedToRegister && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      alignContent: "center"
                                    }}
                                    data-tip={`Posted by: ${postedBy || ""}`}
                                    data-for="user"
                                  >
                                    <Iconify
                                      icon="eva:credit-card-outline"
                                      marginLeft={1}
                                      color={theme.palette.primary.main}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                              </Grid>
                            )}
                          </div>
                        </TableCell>
                        <TableCell sx={{ alignItems: "center" }}>
                          <div data-tip="Select the FEDREF by double clicking" data-for="fedref">
                            {omad && (
                              <HighlighterCellStyles>
                                <div className="value">
                                  <div style={{ textAlign: "left" }}>OMAD:</div>
                                  <Highlighter
                                    className="value-text"
                                    searchWords={[debouncedSearchText]}
                                    autoEscape={true}
                                    textToHighlight={omad || ""}
                                  />
                                </div>
                              </HighlighterCellStyles>
                            )}

                            {imad && (
                              <HighlighterCellStyles>
                                <div className="value">
                                  <div style={{ textAlign: "left" }}>IMAD:</div>
                                  <Highlighter
                                    className="value-text"
                                    searchWords={[debouncedSearchText]}
                                    autoEscape={true}
                                    textToHighlight={imad || ""}
                                  />
                                </div>
                              </HighlighterCellStyles>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          {status === "Confirmed Match" && !patriotSearch && selectedTab === "Credit" && (
                            <div data-tip={`No patriot matches found for ${sender} `} data-for="user">
                              <Iconify
                                icon="eva:checkmark-circle-fill"
                                marginLeft={1}
                                color="#FFCC61"
                                width={20}
                                height={20}
                              />
                            </div>
                          )}
                          {patriotSearch && (
                            <div
                              data-tip={`Patriot search found matches for ${sender} . Do not continue transaction until confirmed to be not a match`}
                              data-for="user"
                            >
                              <Iconify
                                icon="iconoir:file-not-found"
                                marginLeft={1}
                                color="#f22a1c"
                                width={20}
                                height={20}
                              />
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={searchText} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 35, 50]}
            component="div"
            count={wireResults.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <ReactTooltip id="notes" />
      <ReactTooltip id="addNotes" />
      <ReactTooltip id="documents" />
      <ReactTooltip id="sender" />
      <ReactTooltip id="user" />
      <ReactTooltip id="fedref" delayShow={750} />
      {messageOpen && (
        <Dialog
          open={messageOpen}
          onClose={closeMessage}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{errorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {errorMessage}
              {identifier && (
                <Typography>
                  Please{" "}
                  <Link
                    sx={{ textDecoration: "underline" }}
                    href={`sp-select://./ordertracking/order?id=${identifier}`}
                  >
                    open
                  </Link>{" "}
                  in select to choose a trust account and then try again.
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeMessage} variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const HighlighterCellStyles = styled.div`
  align-self: flex-end;
  position: relative;
  cursor: pointer;
  .value {
    color: #000e48;
    font-size: 8px;
    font-weight: 25;
    line-height: 1em;
    margin-top: 2px;
  }

  @media (min-width: 900px) {
    .value {
      font-size: 14px;
    }
    .value {
      display: flex;
      width: 100%;
    }
    .value > *:first-child {
      text-align: left;
      width: 15%;
    }
    .value > *:last-child {
      text-align: left;
      width: 75%;
    }
  }
`;

const TableWrap = styled.div`
  display: flex;
  .column {
    display: flex;
    padding: 4px;
    flex-direction: column;
  }
  .title {
    > div {
      margin-top: 8px;
      text-transform: capitalize;
    }
  }
  .ammount {
    > div {
      text-align: right;
      position: relative;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-left: 16px;
      :first-of-type {
        ::before {
          display: none;
        }
      }
      ::before {
        content: "$";
        position: absolute;
        left: 0;
      }
    }
  }
`;
