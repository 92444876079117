import { Box, Button, Typography, Modal } from "@mui/material";
import { useState } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
interface PropTypes {
  patriotSearchResult: string[];
  onClose?: () => void;
}

export default function PatriotSearchFoundModal(props: PropTypes) {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };
  
  function getNames() {
    return props.patriotSearchResult.map((name, index) => `${index + 1}) ${name}`).join(', ');
  }
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Patriot search matches found: {getNames()}!! Do not continue until
            confirmed not to be a match.
          </Typography>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
