import { useState, useEffect, Fragment } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Iconify from "../../components/Iconify";
import InvoiceAttachments from "./InvoiceAttachments";
import { Attachment } from "../types/Attachment";
import { Wire } from "../types/Wire";
import { InvoiceFile } from "./upload/type";
import UploadSingleFile from "./upload/UploadSingleFile";

export type UploadInvoiceModalProps = {
  onCloseClick: () => void;
  isOpen: boolean;
  selectedWire: Wire | null;
  setSelectedWire: React.Dispatch<React.SetStateAction<Wire | null>>;
  setIsOpenInvoiceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConfirmed: (id: string) => Promise<void>;
  setInvoiceNotApplicable: React.Dispatch<React.SetStateAction<boolean>>;
  invoiceNotApplicable: boolean;
};

export function convertToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      const cleanedBase64 = result.substring(result.indexOf(",") + 1);
      resolve(cleanedBase64);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

const UploadInvoiceModal = ({
  onCloseClick,
  isOpen,
  selectedWire,
  setSelectedWire,
  setIsOpenInvoiceModal,
  setConfirmed,
  setInvoiceNotApplicable,
  invoiceNotApplicable,
}: UploadInvoiceModalProps) => {
  const [selectedInvoice, setSelectedInvoice] = useState<Attachment>();
  const [newInvoiceFile, setNewInvoiceFile] = useState<InvoiceFile | null>(
    null
  );
  const uploadInvoiceWithConfirmation = async () => {
    setConfirmed(selectedWire?.id as string);
    const { data } = await axios.post(
      "api/document/uploadInvoiceWithConfirmation",
      {
        invoiceId: selectedInvoice?.id,
        newInvoiceName: invoiceFileWithoutExtension,
        newInvoiceBase64: newInvoiceFile?.base64,
        transactionId: parseInt(selectedWire?.id as string),
      }
    );
  };

  const invoiceFileWithoutExtension =
    newInvoiceFile?.fileName && newInvoiceFile?.fileName.split(".")[0];

  return (
    <Dialog open={isOpen} onClose={onCloseClick} maxWidth="md">
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Provide Corresponding Invoice
      </DialogTitle>
      <DialogContent>
        <Typography marginBottom={2}>
          {!invoiceNotApplicable &&
            "An invoice is required and will be saved concurrently with the wire confirmation."}
        </Typography>
        <Grid container marginBottom={2} spacing={2}>
          <Grid item xs={6}>
            {!newInvoiceFile && !selectedInvoice && !invoiceNotApplicable && (
              <>
                <Stack
                  direction="row"
                  spacing={3}
                  marginBottom={2}
                  justifyContent="space-between"
                >
                  <UploadSingleFile
                    file={newInvoiceFile}
                    onDrop={async (files: File[]) => {
                      const file = files[0];
                      const invoiceFile: InvoiceFile = {
                        fileName: file.name,
                        base64: (await convertToBase64(file)) as string,
                      };
                      setNewInvoiceFile(invoiceFile);
                    }}
                    label="Upload documents"
                    sx={{ minHeight: "160px" }}
                    disabled={!!selectedInvoice}
                  />
                </Stack>
              </>
            )}
            {newInvoiceFile && !invoiceNotApplicable && (
              <Card sx={{ maxHeight: "160px" }}>
                <CardContent>
                  <Stack direction="row" spacing={3} marginBottom={2}>
                    <Iconify icon="eva:file-text-outline" fontSize={44} />
                    <Typography variant="subtitle2">
                      {newInvoiceFile.fileName}
                    </Typography>
                  </Stack>
                  <TextField
                    fullWidth
                    label="Document will be saved to Closing > Invoices >"
                    value={newInvoiceFile.fileName}
                    onChange={(e) =>
                      setNewInvoiceFile({
                        ...newInvoiceFile,
                        fileName: e.target.value,
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <Iconify
                            icon="eva:close-fill"
                            onClick={() => setNewInvoiceFile(null)}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={6}>
            {!newInvoiceFile && !invoiceNotApplicable && (
              <InvoiceAttachments
                selectedWire={selectedWire}
                selectedInvoice={selectedInvoice}
                newInvoiceFile={newInvoiceFile}
                setSelectedInvoice={setSelectedInvoice}
              />
            )}
          </Grid>
        </Grid>

        <Typography marginBottom={2}>
          {newInvoiceFile &&  !invoiceNotApplicable && (
            <>
              {`Invoice will be saved to Closing
              > Invoices
             "${newInvoiceFile.fileName}" and
              will also be saved with the appended wire confirmation to Post
              Closing
             > Audit Review
             > "${newInvoiceFile.fileName}" w/
              Wire Confirmation.`}
            </>
          )}
          {!newInvoiceFile && !selectedInvoice && !invoiceNotApplicable && (
            <>
              {`Invoice will be saved to Closing >
              Invoices`}
            </>
          )}
        </Typography>

        <Typography marginBottom={2}>
          {selectedInvoice &&  !invoiceNotApplicable && (
            <>
              {`Invoice will be saved with the appended wire confirmation to Post
              Closing > Audit Review > "${selectedInvoice?.description}"`}
            </>
          )}
          {!selectedInvoice && !newInvoiceFile && !invoiceNotApplicable && (
            <>
              {`Invoice will also be saved with the appended wire confirmation to
              Post Closing > Audit Review.`}
            </>
          )}
        </Typography>
        <Typography marginBottom={2}>
          {invoiceNotApplicable &&
            "You have indicated an invoice is not applicable. Uncheck the checkbox to provide an invoice"}
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          marginBottom={2}
        >
          <FormControlLabel
            control={<Checkbox />}
            label="invoice not applicable"
            onClick={() => {
              setInvoiceNotApplicable(!invoiceNotApplicable);
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button
            color="success"
            variant="outlined"
            size="medium"
            disabled={
              !selectedInvoice && !newInvoiceFile && !invoiceNotApplicable
            }
            onClick={() => {
              invoiceNotApplicable
                ? setConfirmed(selectedWire?.id as string)
                : uploadInvoiceWithConfirmation();
              setIsOpenInvoiceModal(false);
              setNewInvoiceFile(null);
              setSelectedInvoice(undefined);
              setSelectedWire(null);
            }}
          >
            <Iconify fontSize={22} icon="eva:checkmark-fill" />
          </Button>
          <Button
            color="error"
            variant="outlined"
            size="medium"
            onClick={() => {
              setIsOpenInvoiceModal(false);
              setNewInvoiceFile(null);
              setSelectedInvoice(undefined);
              setSelectedWire(null);
              setInvoiceNotApplicable(false);
            }}
          >
            <Iconify fontSize={22} icon="eva:close-fill" />
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default UploadInvoiceModal;
