// components
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  wires: getIcon("ic_banking"),
  invoice: getIcon("ic_invoice"),
  booking: getIcon("ic_booking"),
};

const navConfig = [
  // TRANSFER
  // ----------------------------------------------------------------------
  {
    subheader: "title funding",
    items: [
      {
        title: "Wires",
        path: window.location.pathname,
        icon: ICONS.wires,
      },
    ],
  },
];

export default navConfig;
