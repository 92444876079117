// @mui
import { Stack, Button, Typography } from '@mui/material';
// assets
import { DocIllustration } from '../../../assets';
import { UserContext } from '../../../contexts/UserContext';
import { useContext } from 'react';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useContext(UserContext);
  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      {/* <DocIllustration sx={{ width: 1 }} /> */}

      <div>
        {/* <Typography gutterBottom variant="subtitle1">
          Hi, {user?.name}
        </Typography> */}
        {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need help?
          <br /> Please check our docs
        </Typography> */}
      </div>

      {/* <Button variant="contained">Documentation</Button> */}
    </Stack>
  );
}
