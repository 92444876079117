import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import { UploadProps } from "./type";

import Iconify from "../../../components/Iconify";
import { Box } from "@mui/material";
import BlockContent from "./BlockContent";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  overflow: "hidden",
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
}));

// ----------------------------------------------------------------------

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  sx,
  label,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      multiple: false,
      ...other,
    });

  return (
    <>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
          ...(file && {
            padding: "12% 0",
          }),
          ...sx,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: 16,
            top:16
          }}
        >
          <Iconify icon="eva:file-add-outline" fontSize={22} />
        </Box>
        <input {...getInputProps()} />

        <BlockContent label={label} />
      </DropZoneStyle>
      {helperText && helperText}
    </>
  );
}
