import styled from "@emotion/styled";
export function AddWireNotes({ ...props }) {
  if (!props.hasnotes) {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 144.000000 146.000000"
        preserveAspectRatio="xMidYMid meet"
        {...props}
      >
        <g
          transform="translate(0.000000,146.000000) scale(0.100000,-0.100000)"
          fill="#707898"
          stroke="none"
        >
          <path
            d="M247 1399 c-58 -14 -91 -34 -135 -84 -60 -68 -64 -93 -60 -405 l3
  -275 28 -47 c15 -26 42 -59 60 -72 65 -50 102 -56 341 -56 l221 0 174 -200
  c245 -281 251 -281 251 11 l0 186 45 6 c60 9 115 38 153 82 62 69 63 79 60
  401 l-3 291 -30 49 c-37 59 -113 108 -181 118 -71 9 -883 5 -927 -5z m513
  -169 c18 -18 20 -33 20 -130 l0 -111 112 2 c105 1 113 0 130 -21 20 -25 16
  -57 -10 -77 -10 -9 -53 -13 -124 -13 l-108 0 0 -110 c0 -97 -2 -112 -20 -130
  -26 -26 -46 -25 -75 5 -23 23 -25 31 -25 130 l0 105 -107 0 c-85 0 -110 3
  -125 17 -22 20 -23 56 -2 77 18 19 13 18 137 16 l97 -2 0 111 c0 98 2 113 20
  131 11 11 29 20 40 20 11 0 29 -9 40 -20z"
          />
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 144.000000 146.000000"
        preserveAspectRatio="xMidYMid meet"
        {...props}
      >
        <g
          transform="translate(0.000000,146.000000) scale(0.100000,-0.100000)"
          fill="rgb(238, 114, 90)"
          stroke="none"
        >
          <path
            d="M200 1379 c-55 -25 -95 -62 -123 -114 -21 -38 -22 -52 -22 -330 0
  -278 1 -292 22 -330 28 -52 78 -99 128 -122 34 -15 76 -18 271 -21 l232 -4
  167 -191 c92 -106 178 -199 191 -207 45 -28 53 -4 55 170 1 85 4 172 7 192 4
  36 6 37 57 48 98 20 172 89 195 182 7 28 10 138 8 308 -3 251 -4 267 -25 306
  -28 53 -91 107 -142 122 -27 9 -180 12 -507 12 -450 0 -471 -1 -514 -21z m950
  -291 c13 -25 13 -31 0 -55 l-16 -28 -409 -3 c-408 -2 -410 -2 -427 19 -22 26
  -23 48 -2 77 l15 22 412 -2 411 -3 16 -27z m-11 -234 c26 -33 26 -45 0 -78
  l-20 -26 -399 0 -399 0 -20 26 c-12 15 -21 32 -21 39 0 7 9 24 21 39 l20 26
  399 0 399 0 20 -26z"
          />
        </g>
      </svg>
    );
  }
}
export const BaseButton = styled.button<{
  isLoading?: boolean;
  isDisabled?: boolean;
}>`
  align-items: center;
  background-color: ${(props) => (props.isDisabled ? "#e6e6e6" : "#1f2b5e")};
  border-radius: 4px;
  box-shadow: 0px 0px 0px 1px transparent inset,
    0px 0em 0px 0px rgb(34 36 38 / 15%) inset;
  color: #fff;
  cursor: ${(props) =>
    props.isLoading || props.isDisabled ? "default" : "pointer"};
  display: flex;
  font-weight: 600;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  margin-right: 16px;
  min-width: 80px;
  padding: 0 24px;
  position: relative;
  text-transform: capitalize;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  /* opacity: ${(props) => props.isLoading && "0.5"}; */
  pointer-events: ${(props) => props.isLoading || (props.isDisabled && "none")};
  :last-of-type {
    margin-right: 0;
  }
  :hover {
    background-color: ${(props) => (props.isDisabled ? "#e6e6e6" : "#000e48")};
    box-shadow: 0px 0px 0px 1px transparent inset,
      0px 0em 0px 0px rgb(34 36 38 / 15%) inset;
  }
`;

export const GhostButton = styled.button`
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c2c5d3;
  box-shadow: 0px 0px 0px 1px transparent inset,
    0px 0em 0px 0px rgb(34 36 38 / 15%) inset;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  margin-right: 16px;
  min-width: 80px;
  padding: 0 24px;
  position: relative;
  text-transform: capitalize;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  :last-of-type {
    margin-right: 0;
  }
  :hover {
    color: rgba(0, 0, 0, 1);
    border: 1px solid #999fb6;
    box-shadow: 0px 0px 0px 1px transparent inset,
      0px 0em 0px 0px rgb(34 36 38 / 15%) inset;
  }
`;

export function DownloadFile({ ...props }) {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.3354 1.32918L8.17705 1.25L8 1.25L3 1.25C2.92679 1.25 2.85181 1.24934 2.77812 1.24869C2.61425 1.24726 2.45677 1.24588 2.33938 1.2519C2.19542 1.25927 1.83248 1.27843 1.55545 1.55545C1.27843 1.83248 1.25927 2.19542 1.2519 2.33938C1.24588 2.45677 1.24726 2.61426 1.24869 2.77812C1.24934 2.85181 1.25 2.92679 1.25 3L1.25 11C1.25 11.076 1.24938 11.1567 1.24875 11.2394C1.24731 11.4274 1.24579 11.6255 1.25128 11.8018C1.25934 12.0604 1.28299 12.3568 1.36926 12.6358C1.45982 12.9286 1.6304 13.2366 1.94954 13.4586C2.2606 13.6749 2.62776 13.75 3 13.75H15C15.3722 13.75 15.7394 13.6749 16.0504 13.4586C16.3696 13.2366 16.5402 12.9286 16.6307 12.6358C16.717 12.3568 16.7407 12.0604 16.7487 11.8018C16.7542 11.6255 16.7527 11.4274 16.7512 11.2394C16.7506 11.1567 16.75 11.076 16.75 11V5C16.75 4.92398 16.7506 4.84327 16.7512 4.76059C16.7527 4.57263 16.7542 4.37449 16.7487 4.19815C16.7407 3.93959 16.717 3.64316 16.6307 3.36419C16.5402 3.07137 16.3696 2.76337 16.0504 2.54143C15.7394 2.32511 15.3722 2.25 15 2.25H10.177L8.3354 1.32918ZM7.46967 8.11611L8.75 9.39644V7.58578V6C8.75 5.86192 8.86192 5.75 9 5.75C9.13807 5.75 9.25 5.86192 9.25 6V7.58578V9.39644L10.5303 8.11611L10.8232 7.82322C10.9209 7.72559 11.0791 7.72559 11.1768 7.82322C11.2744 7.92085 11.2744 8.07914 11.1768 8.17677L9.17677 10.1768C9.07914 10.2744 8.92085 10.2744 8.82322 10.1768L6.82322 8.17677C6.72559 8.07914 6.72559 7.92085 6.82322 7.82322C6.92085 7.72559 7.07914 7.72559 7.17677 7.82322L7.46967 8.11611Z"
        stroke="black"
        stroke-width="1.5"
      />
    </svg>
  );
}
