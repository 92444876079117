import * as React from "react";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Toolbar,
  InputAdornment,
  Grid,
  Autocomplete,
  TextField,
  FormControlLabel,
  Switch,
  Box,
  Checkbox,
} from "@mui/material";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  DateRangePicker,
  DateRange,
} from "@mui/x-date-pickers-pro/DateRangePicker";
// components
import Iconify from "../components/Iconify";
import InputStyle from "../components/InputStyle";
import { Status } from "../pages/types/Status";
import { Dispatch, SetStateAction } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  padding: theme.spacing(2, 3, 2, 3),
}));

// ----------------------------------------------------------------------

type Props = {
  numSelected: number;
  filterName: string;
  onFilterName: (value: string) => void;
  selectedStatus: Status;
  setSelectedStatus: Dispatch<SetStateAction<Status>>;
  bankAccountList: string[];
  selectedBankAccounts: string[];
  setSelectedBankAccounts: React.Dispatch<React.SetStateAction<string[]>>;
  internalTransfers: boolean;
  setInternalTransfers: React.Dispatch<React.SetStateAction<boolean>>;
  dateRange: DateRange<Dayjs>;
  setDateRange: React.Dispatch<React.SetStateAction<DateRange<Dayjs>>>;
};

export default function ListToolbar({
  numSelected,
  filterName,
  onFilterName,
  selectedStatus,
  setSelectedStatus,
  bankAccountList,
  selectedBankAccounts,
  setSelectedBankAccounts,
  internalTransfers,
  setInternalTransfers,
  dateRange,
  setDateRange,
}: Props) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const lastMonth = dayjs().subtract(1, "month");
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? "primary.main" : "text.primary",
          bgcolor: isLight ? "primary.lighter" : "primary.dark",
        }),
      }}
    >
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item lg={4} xs={12}>
          <InputStyle
            fullWidth
            value={filterName}
            onChange={(event) => onFilterName(event.target.value)}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={"eva:search-fill"}
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item md={3} xs={12} justifyContent="flex-start">
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: "Start Date", end: "End Date" }}
          >
            <DateRangePicker
              defaultCalendarMonth={lastMonth}
              calendars={2}
              value={dateRange}
              onChange={(newValue) => {
                setDateRange(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Grid>
     
        <Grid item lg={5} xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item md={4} xs={12}>
              <Autocomplete
                multiple
                limitTags={1}
                options={bankAccountList}
                value={selectedBankAccounts}
                getOptionLabel={(option) => option}
                onChange={(e, value) => setSelectedBankAccounts(value)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bank Account"
                    placeholder="Start typing..."
                    sx={{ maxHeight: "100%" }}
                  />
                )}
                fullWidth
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Autocomplete
                fullWidth
                options={["Claimed", "Unclaimed", "All"] as Status[]}
                value={selectedStatus}
                getOptionLabel={(option) => option}
                onChange={(e, value) => setSelectedStatus(value || "All")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    placeholder="Start typing..."
                  />
                )}
              />
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
              sx={{
                [theme.breakpoints.up("lg")]: {
                  alignSelf: "center",
                },
              }}
            >
              <Box
                display="flex"
                sx={{
                  [theme.breakpoints.up("lg")]: {
                    justifyContent: "center",
                  },
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={internalTransfers}
                      onChange={() => setInternalTransfers(!internalTransfers)}
                      name="internalTransfers"
                    />
                  }
                  label="Internal Transfers"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
