// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import Settings from "./components/settings";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import ThemeColorPresets from "./components/ThemeColorPresets";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { ReactElement, useEffect, useState } from "react";
import axios from "axios";
import { UserProvider } from "./contexts/UserContext";
import { Backdrop, CircularProgress, Fade, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "8308c0316512b9c5f8a3b2442c585736Tz01NzEwOCxFPTE3MDQ0OTE0NjYxMzYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);
const AppLoadingSpinner = ({
  isAuthenticated
}: {
  isAuthenticated: boolean;
}) => {
  return (
    <Fade
      in={!isAuthenticated}
      style={{
        transitionDelay: !isAuthenticated ? "800ms" : "0ms"
      }}
      unmountOnExit
    >
      <Backdrop sx={{ backgroundColor: "#fff" }} open={!isAuthenticated}>
        <CircularProgress color="primary" size={72} />
        <Typography marginLeft={3}>Loading...</Typography>
      </Backdrop>
    </Fade>
  );
};

const EnsureAuthenticated = ({ children }: { children: ReactElement }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const doLogin = async () => {
      const { data } = await axios.get("/api/login/isAuthenticated", {
        headers: { Pragma: "no-cache" }
      });
      setIsAuthenticated(data.isAuthenticated);
      if (!data.isAuthenticated) {
        const { pathname, search } = window.location;
        window.location.href = `/api/login/?returnUrl=${pathname}${search}`;
      }
    };

    doLogin();
  }, []);
  return isAuthenticated ? (
    children
  ) : (
    <AppLoadingSpinner isAuthenticated={isAuthenticated} />
  );
};

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <EnsureAuthenticated>
        <UserProvider>
          <ThemeProvider>
            <ThemeColorPresets>
              <RtlLayout>
                <MotionLazyContainer>
                  <ProgressBarStyle />
                  <Settings />
                  <ScrollToTop />
                  <Router />
                </MotionLazyContainer>
              </RtlLayout>
            </ThemeColorPresets>
          </ThemeProvider>
        </UserProvider>
      </EnsureAuthenticated>
    </LocalizationProvider>
  );
}
