import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Alert
} from "@mui/material";
import { useEffect, useState } from "react";
import { CustomersBankAccount, CustomersBankTransaction } from "./types";
import axios from "axios";
import { fDate } from "../../utils/formatTime";
import { fCurrency } from "../../utils/formatNumber";
import CustomersBankLogo from "./CustomersBankLogo";
import Page from "../../components/Page";
import useSettings from "../../hooks/useSettings";

const CustomersBankTransactions = () => {
  const [transactions, setTransactions] = useState<CustomersBankTransaction[]>([]);
  const [accounts, setAccounts] = useState<CustomersBankAccount[]>([]);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getAndSetTransactions = async () => {
    try {
      const { data } = await axios.get<CustomersBankTransaction[]>("/customersbanktransaction/gettransactions");
      setTransactions(data);
    } catch (err) {
      setError("Failed to fetch transactions");
    } finally {
      setLoadingTransactions(false);
    }
  };

  const getAndSetAccounts = async () => {
    try {
      const { data } = await axios.get<CustomersBankAccount[]>("/customersbanktransaction/getaccounts");
      setAccounts(data);
    } catch (err) {
      setError("Failed to fetch accounts");
    } finally {
      setLoadingAccounts(false);
    }
  };

  useEffect(() => {
    getAndSetAccounts();
    getAndSetTransactions();
  }, []);

  if (loadingTransactions || loadingAccounts) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Page title="Customers Bank" paddingLeft={3}>
      <CustomersBankLogo />
      <Stack direction="row" marginBottom={3} marginTop={2} spacing={2}>
        {accounts.map((a) => (
          <Card key={a.accountNumber} sx={{ width: 400, boxShadow: 3 }}>
            <CardContent>
              <Box marginBottom={1}>
                <Typography variant="h6" fontWeight="bold">
                  Account #{a.accountNumber}
                </Typography>
              </Box>
              <Box marginBottom={1}>
                <Typography variant="subtitle1" color="textSecondary">
                  Balance
                </Typography>
                <Typography variant="body1">{fCurrency(a.balance)}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" color="textSecondary">
                  Available Balance
                </Typography>
                <Typography variant="body1">{fCurrency(a.availableBalance)}</Typography>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Code Description</TableCell>
              <TableCell>Effective Date</TableCell>
              <TableCell>Posting Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Serial Number</TableCell>
              <TableCell>Control Number</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Rail</TableCell>
              <TableCell>Customers Bank ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow
                key={transaction.id}
                sx={{
                  borderBottom: "1px solid #212b361f",
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:first-of-type td, &:first-of-type th": {
                    border: 0
                  }
                }}
              >
                <TableCell>{transaction.description}</TableCell>
                <TableCell>{transaction.transactionCode}</TableCell>
                <TableCell>{transaction.transactionCodeDescription}</TableCell>
                <TableCell>{fDate(transaction.effectiveDate)}</TableCell>
                <TableCell>{fDate(transaction.postingDate)}</TableCell>
                <TableCell sx={{ color: transaction.amount < 0 ? "error.dark" : "success.dark" }}>
                  {fCurrency(transaction.amount)}
                </TableCell>
                <TableCell>{transaction.serialNumber}</TableCell>
                <TableCell>{transaction.controlNumber}</TableCell>
                <TableCell>{transaction.transactionType}</TableCell>
                <TableCell>{transaction.rail}</TableCell>
                <TableCell>{transaction.customersBankId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  );
};

export default CustomersBankTransactions;
