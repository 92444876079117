import { fCurrency } from "../../utils/formatNumber";
import { Wire } from "../types/Wire";

export const sendNewWirePushNotification = (wire: Wire) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (isMobile) return;
  if (
    window.Notification && window.Notification.permission === "granted" &&
    document.visibilityState === "hidden"
  ) {
    const title = `${
      wire.transactionType === "Credit" ? "Incoming Wire" : "Outgoing Wire"
    } ${wire.titleFileNumber ? ` ${wire.titleFileNumber}` : ""}`;
    const body = `${fCurrency(wire.amount)} ${
      wire.transactionType === "Credit"
        ? `Received from ${wire.sender || "unknown"}`
        : `Sent to ${wire.receiver || "unknown"}`
    }`;
    new Notification(title, {
      body,
      icon: "/favicon/apple-touch-icon.png",
      requireInteraction: true,
      tag: "wire"
    });
  }
};
