// @mui
import { Container } from "@mui/material";
// hooks
import useSettings from "../hooks/useSettings";
// components
import Page from "../components/Page";
import WiresTable from "./WiresTable";

// ----------------------------------------------------------------------

export default function PageOne() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Wires">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <WiresTable />
      </Container>
    </Page>
  );
}
