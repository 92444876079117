import { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import axios from "axios";

import { fCurrency } from "../../utils/formatNumber";
import { Wire } from "../types/Wire";

export type PostToSelectModalProps = {
  wire: Wire | null;
  wireType: "incoming" | "outgoing";
  onCloseClick: () => void;
  onPendingTransactionClick: (
    transactionId: string,
    sendToEarnestMoney: boolean,
    id: number
  ) => void;
  onNewTransactionClick: (
    sendToEarnestMoney: boolean,
    id: number,
    applyToType: "Lender" | "AllBuyers"
  ) => void;
  id: number;
  isOpen: boolean;
  postingTransactionId: string | null;
  selectedTab: string;
};

type PendingWire = {
  transactionId: string;
  name: string;
  amount: number;
};

const PostToSelectModal = ({
  wire,
  wireType,
  onCloseClick,
  onPendingTransactionClick,
  onNewTransactionClick,
  id,
  isOpen,
  postingTransactionId,
  selectedTab
}: PostToSelectModalProps) => {
  const [pendingWires, setPendingWires] = useState<PendingWire[]>([]);
  const [isLoadingPendingWires, setIsLoadingPendingWires] = useState(true);
  const [sendToEarnestMoney, setSendToEarnestMoney] = useState(false);
  const [selectedPostingOption, setSelectedPostingOption] = useState<
    string | null
  >(null);

  let pendingWiresState: "Loading" | "Loaded" | "Empty" = "Loading";
  if (isLoadingPendingWires) {
    pendingWiresState = "Loading";
  } else if (pendingWires.length) {
    pendingWiresState = "Loaded";
  } else {
    pendingWiresState = "Empty";
  }

  useEffect(() => {
    const getPendingWires = async () => {
      setIsLoadingPendingWires(true);
      const { data } = await axios.get(
        `/api/Transaction/GetPending${wireType}Wires?filenumber=${wire?.titleFileNumber}`
      );
      setPendingWires(data);
      setIsLoadingPendingWires(false);
    };

    getPendingWires();
  }, [wire, wireType]);

  const getPendingWiresDisplay = () => {
    switch (pendingWiresState) {
      case "Loading":
        return (
          <div className="item" style={{ fontStyle: "italic" }}>
            Loading pending wires...
          </div>
        );
      case "Loaded":
        return pendingWires.map((wire) => (
          <Button
            key={wire.transactionId}
            onClick={() => {
              if (postingTransactionId === null) {
                setSelectedPostingOption(wire.transactionId);
                onPendingTransactionClick(
                  wire.transactionId,
                  sendToEarnestMoney,
                  id
                );
              }
            }}
            sx={{ marginBottom: 1 }}
            variant="outlined"
          >
            {postingTransactionId &&
              selectedPostingOption === wire.transactionId
              ? "Posting..."
              : `${fCurrency(wire.amount)} ${wireType === "incoming" ? "from" : "to"
              } ${wire.name}`}
          </Button>
        ));
      default:
        return <div className="item">No pending wires found</div>;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" marginBottom={3}>
        <Table size="small">
          <TableBody>
            <TableRow
              key="fileNumber"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ verticalAlign: "top" }}>File</TableCell>
              <TableCell sx={{ verticalAlign: "top" }}>
                {wire?.titleFileNumber}
              </TableCell>
            </TableRow>
            <TableRow
              key="amount"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ verticalAlign: "top" }}>Amount</TableCell>
              <TableCell sx={{ verticalAlign: "top" }}>
                {wire?.amount && fCurrency(wire?.amount)}
              </TableCell>
            </TableRow>
            <TableRow
              key="notes"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ verticalAlign: "top" }}>Notes</TableCell>
              <TableCell sx={{ verticalAlign: "top" }}>
                {wire?.wireNotes}
              </TableCell>
            </TableRow>
            <TableRow
              key="senderRecipient"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ verticalAlign: "top" }}>
                {wireType === "incoming" ? "Sender" : "Recipient"}
              </TableCell>
              <TableCell sx={{ verticalAlign: "top" }}>
                {wireType === "incoming" ? wire?.sender : wire?.receiver}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Grid container marginTop={2} marginBottom={7}>
          {selectedTab === "Credit" && <Grid item xs={12}>
            <FormControl
              component="fieldset"
              sx={{ marginLeft: 3 }}
              variant="standard"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendToEarnestMoney}
                      onChange={() => setSendToEarnestMoney((v) => !v)}
                    />
                  }
                  label="Send to Earnest Money"
                />
              </FormGroup>
            </FormControl>
          </Grid>}
          <Grid item md={5} xs={12}>
            <FormControl component="fieldset" sx={{ m: 3 }} variant="standard">
              <FormLabel component="legend" sx={{ marginBottom: 1 }}>
                New Transactions:
              </FormLabel>
              <FormGroup>
                <Button
                  onClick={() => {
                    if (postingTransactionId === null) {
                      setSelectedPostingOption("AllBuyers");
                      onNewTransactionClick(
                        sendToEarnestMoney,
                        id,
                        "AllBuyers"
                      );
                    }
                  }}
                  sx={{ marginBottom: 1 }}
                  variant="outlined"
                >
                  {postingTransactionId && selectedPostingOption === "AllBuyers"
                    ? "Posting..."
                    : `${wireType === "incoming" ? "From" : "Apply to"
                    } All Buyers`}
                </Button>
                <Button
                  onClick={() => {
                    if (postingTransactionId === null) {
                      setSelectedPostingOption("Lender");
                      onNewTransactionClick(sendToEarnestMoney, id, "Lender");
                    }
                  }}
                  variant="outlined"
                >
                  {postingTransactionId && selectedPostingOption === "Lender"
                    ? "Posting..."
                    : `${wireType === "incoming" ? "From" : "Apply to"} Lender`}
                </Button>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item md={7} xs={12}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend" sx={{ marginBottom: 1 }}>
                Apply to Pending Transactions:
              </FormLabel>
              <FormGroup>{getPendingWiresDisplay()}</FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PostToSelectModal;
