import axios from "axios";
import { ReactNode, createContext, useState, useEffect } from "react";

// ----------------------------------------------------------------------
const initialState: UserContextProps = {
  user: null,
};

declare global {
  interface Window {
    clarity: any;
  }
}

const UserContext = createContext(initialState);

type UserProviderProps = {
  children: ReactNode;
};

type User = {
  name: string;
  initials: string;
  email: string;
};

type UserContextProps = {
  user: User | null;
};

const getInitials = (name: string) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let regexArray = [...name.matchAll(rgx)] || [];
  const initials = (
    (regexArray.shift()?.[1] || "") + (regexArray.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
};

function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState<User | null>(null);

  const getAndSetUser = async () => {
    const { data } = await axios.post("/api/login/GetUserPersonalInfo");
    const lastName = data.name.split(", ").slice(0, -1).join(" ");
    const firstName = data.name.split(", ").slice(-1).join(" ");
    const name = `${firstName} ${lastName}`;
    const email = data.email.toLowerCase();
    const initials = getInitials(name);
    setUser({ name, initials, email });
    window.clarity("set", "user", `${name}`);
  };

  useEffect(() => {
    getAndSetUser();
  }, []);

  useEffect(() => {
    if (user) {
      const setupBeamer = async () => {
        const beamerConfigScript = document.createElement("script");
        beamerConfigScript.text = ` 
          var beamer_config = {
            product_id : 'WdqQStfQ41060',
            user_email: '${user.email}',
            user_lastname: '${user.name}',
            user_id: '${user.email}',
            filter_by_url: true,
            file_number: 'TitleQ'
          };`;
        document.head.appendChild(beamerConfigScript);

        const beamerSrcScript = document.createElement("script");
        beamerSrcScript.type = "text/javascript";
        beamerSrcScript.src = "https://app.getbeamer.com/js/beamer-embed.js";

        document.head.appendChild(beamerSrcScript);
      };

      setupBeamer();
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
}

export { UserProvider, UserContext };
