import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { Attachment } from "../types/Attachment";
import { Wire } from "../types/Wire";
import { InvoiceFile } from "./upload";

interface IProps {
  selectedWire: Wire | null;
  selectedInvoice: Attachment | undefined;
  newInvoiceFile: InvoiceFile | null;
  setSelectedInvoice: React.Dispatch<
    React.SetStateAction<Attachment | undefined>
  >;
}
export default function InvoiceAttachments(props: IProps) {
  const [invoiceList, setInvoiceList] = useState<Attachment[]>([]);
  const [selectedInvoiceInput, setSelectedInvoiceInput] = useState<string>("");
  const debouncedInvoiceInput = useDebounce(selectedInvoiceInput, 500);

  const { selectedWire, selectedInvoice, setSelectedInvoice, newInvoiceFile } =
    props;

  const isNewInvoiceFile = newInvoiceFile !== null;

  const handleTextChange = async (searchText: string) => {
    const { data } = await axios.get(
      `/api/document/search?ordernumber=${selectedWire?.titleFileNumber}&searchtext=${searchText}`
    );
    setInvoiceList(data);
  };
  const handleOptionChange = (
    e: React.SyntheticEvent<Element, Event>,
    option: string | Attachment | null | undefined,
    reason: string
  ) => {
    if (!option) {
      setSelectedInvoice(undefined);
      return;
    }
    if (option && typeof option === "object") {
      setSelectedInvoice(option);
    }
  };

  useEffect(() => {
    if (debouncedInvoiceInput) handleTextChange(debouncedInvoiceInput);
  }, [debouncedInvoiceInput]);

  return (
    <>
      <Card sx={{ height: "160px" }}>
        <CardContent>
          <Typography variant="subtitle2" marginBottom={2}>
            {selectedInvoice
              ? "Invoice Already saved to Momentum"
              : "Choose an invoice from Momentum"}
          </Typography>
          <Box>
            <Autocomplete
              value={selectedInvoice}
              onChange={handleOptionChange}
              forcePopupIcon={false}
              options={invoiceList}
              fullWidth
              inputValue={selectedInvoiceInput}
              disabled={isNewInvoiceFile}
              onInputChange={(e, newValue) => setSelectedInvoiceInput(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autocomplete: "new-password",
                  }}
                  fullWidth
                  label="Home Page Document"
                  placeholder="Start Typing"
                />
              )}
              getOptionLabel={(option) =>
                `${option.category} ${option.description}` || ""
              }
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
